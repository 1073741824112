<template>
  <div>
    <img v-if="src!=''" :src="src" :title="title" style="width:18px;position: relative;top:2.5px;" />
    <span v-else>{{language.componentLabel.labelNA}}</span>
  </div>
</template>
<script>
export default {
  props: ["row"],
  computed: {
    src() {
      return this.row.c_device.src;
    },
    title() {
      return this.row.c_device.title;
    },
  },
};
</script>